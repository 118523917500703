$border-grey: #EFEEEB;

.enrollment-header {
    border-bottom: 2px solid $border-grey;
    padding: 0 16px 0 8px;

    .logo {
        width: 132px;
        height: 132px;

        @media (max-width: 580px) {
            width: 100px;
            height: 100px;
        }
    }

    .logout-button {
        color: #555;
        border-color: #555;
    }
}