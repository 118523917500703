.change-password-container {
    padding: 200px 0;

    .change-password-box {
        width: 500px;
        border: 2px solid #EFEEEB;

        .change-password-box-content {
            padding: 64px 24px;

            .change-password-title {
                padding-bottom: 24px;
            }

            .change-password-button {
                margin-top: 8px;
                margin-bottom: 24px;
                border-radius: 10px;
                color: #FFFFFF;
                background-color: #555;
            }
        }
    }
}