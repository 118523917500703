$border-grey: #EFEEEB;

#main-text {
    font-style: italic;
    text-shadow: 2px 2px 2px #aaa;

    @media (max-width: 851px) {
        font-size: 2rem;
    }

    @media (max-width: 939px) {
        text-align: center;
    }
}

#secondary-text {
    font-style: italic;
    text-shadow: 2px 2px 2px #aaa;
    font-size: 2rem;

    @media (max-width: 851px) {
        font-size: 1.3rem;
    }

    @media (max-width: 939px) {
        text-align: center;
    }
}

.introduction-contianer {

    border-bottom: 2px solid $border-grey;

    .right-side {
        width: 34%;
        padding: 48px;
    }

    .left-side {
        width: 64%;

        .main-image {
            object-fit: contain;
        }
    }

    @media (max-width: 939px) {
        flex-direction: column;

        .right-side {
            width: 100%;
        }

        .left-side {
            width: 100%;
        }
    }
}

.services-container {
    padding: 8px 48px;
    border-bottom: 2px solid $border-grey;

    .full-width {
        width: 100%;

        .MuiGridListTile-tile {
            display: flex;
        }

        .circle-image-container {
            border-radius: 100%;
            box-shadow: 2px 2px 5px 0px #aaa;
            transition: all .2s ease-in-out;

            &:hover {
                cursor: pointer;
                transform: scale(1.2);
            }

            .circle-image {
                display: block;
                object-fit: cover;
                border-radius: 100%;
                transition: all .2s ease-in-out;
                -webkit-filter: grayscale(100%);
                /* Ch 23+, Saf 6.0+, BB 10.0+ */
                filter: grayscale(100%);
                /* FF 35+ */

                &:hover {
                    -webkit-filter: grayscale(0%);
                    /* Ch 23+, Saf 6.0+, BB 10.0+ */
                    filter: grayscale(0%);
                    /* FF 35+ */
                }
            }
        }

        .circle-text {
            padding-left: 20px;
            text-shadow: 2px 2px 2px #aaa;
            width: 170px;

            @media (max-width: 1126px) {
                padding-left: 10px;
            }

            @media (max-width: 1096px) {
                padding-left: 10px;
                padding-top: 10px;
                text-align: center;
            }

            @media (max-width: 1067px) {
                text-align: center;
                padding-left: 0;
                padding-top: 10px;
            }
        }
    }
}

.prices-container {
    padding: 48px;

    .full-width {
        width: 100%;
    }

    .price-item {
        padding: 8px 0;
    }
}

.mapouter {
    position: relative;
    text-align: right;
    height: 500px;
    width: 100%;

    .gmap_canvas {
        overflow: hidden;
        background: none !important;
        height: 500px;
        width: 100%;

        #gmap_canvas {
            border: none;
        }
    }
}

.link {
    .map-image {
        width: 100%;
    }

    &:hover {
        cursor: pointer;
    }
}