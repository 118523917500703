.login-container {
    padding: 200px 0;

    .login-box {
        width: 500px;
        border: 2px solid #EFEEEB;

        .login-box-content {
            padding: 64px 24px;

            .login-title {
                padding-bottom: 24px;
            }

            .login-button {
                margin-top: 8px;
                margin-bottom: 24px;
                border-radius: 10px;
                color: #FFFFFF;
                background-color: #555;
            }

            .login-button:disabled {
                background-color: #b7b7b7;
            }

            .info-box {
                background-color: #eee;
                padding: 16px;
                border-radius: 10px;
            }
        }
    }
}