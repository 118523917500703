$border-grey: #EFEEEB;

.footer {
    padding: 16px 8px;

    .MuiTypography-body1 {
        font-size: 0.9rem;
    }

    .MuiGrid-spacing-xs-2 > .MuiGrid-item.separator-line  {
        padding: 0;
    }

    .separator-line {
        padding: 0;
        margin-left: 16px;
        margin-right: 16px;
        height: 60px;
        border-left: 2px solid $border-grey;
    }

    @media (max-width: 383px) {
        .header-content {
            visibility: hidden;
        }
    }

    @media (min-width: 672px) and (max-width: 793px) {
        .header-content {
            visibility: hidden;
        }
    }

    .icon {
        margin: 0 4px 0 0;
    }
}
.link {
    color: inherit;

    &:hover {
        cursor: pointer;
    }
}

.link.MuiTypography-colorPrimary {
    color: inherit;
}