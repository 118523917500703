.dialog-title {
    display: flex;
    align-items: center;
    text-shadow: 2px 2px 2px #aaa;
}

.MuiDialogTitle-root.services-title {
    padding: 32px;
}

.MuiDialogContent-root.services-dialog-content {
    padding: 8px 32px 32px 32px;
}

.dialog-content {
    color: #555;
}

.price-item-container {
    height: 75px;
}

#pricesContentDesktop {

    @media (max-width: 810px) {
        display: none;
    }

    .price-item {
        display: flex;
        justify-content: center;
        padding: 6px 16px;
        background-color: #EFEEEB70;
        margin: 8px;
        transition: all .2s ease-in-out;

        .price-per-occasion {
            display: none;
        }

        &:hover {
            transform: scale(1.2, 1.2);
            cursor: default;

            .price-per-occasion {
                display: block;
            }
        }
    }
}

#pricesContentMobile {
    display: none;

    @media (max-width: 810px) {
        display: block;
    }

    @media (max-width: 500px) {

        .price-text,
        .price-title {
            font-size: 0.8rem;
        }
    }

    .price-title {
        padding-right: 8px;
    }

    .price-container {
        margin: 4px;
    }

    .price-item {
        display: flex;
        justify-content: center;
        padding: 6px 16px;
        background-color: #EFEEEB70;
        margin: 8px;
    }
}

.price-text {
    text-align: center;
}

.right-gap {
    padding: 0 48px 0 0;
}

.image {
    object-fit: cover;
    height: 204px;
    width: 204px;
    border-radius: 100%;

    @media (max-width: 400px) {
        height: 174px;
        width: 174px;
    }

    &:hover {
        cursor: pointer;
    }
}

.small-image {
    object-fit: cover;
    height: 130px;
    width: 130px;
    border-radius: 0;
    border: 1px solid #aaa;

    &:hover {
        cursor: pointer;
    }

    @media (max-width: 940px) {
        height: 100px;
        width: 100px;
    }

    @media (max-width: 505px) {
        height: 200px;
        width: 200px;
    }

    @media (max-width: 380px) {
        height: 150px;
        width: 150px;
    }
}

#service-dialog-content .small-label {
    font-size: 0.6rem;
    font-style: italic;
}

.button-container {
    margin: 0 0 16px 0;
}

.preview-dialog-content-container {
    height: 100%;
}

.stepper-image {
    max-height: 100vh;
    max-width: 400;
    overflow: hidden;
    display: block;
    object-fit: contain;
}

#preview-dialog-content {
    padding: 0;

    &:first-child {
        padding-top: 0;
    }
}

.image-container {
    background-color: #000;
    height: 100%;
}

#preview-close-button {
    position: absolute;
    right: 12px;
    top: 12px;
    z-index: 1;
    color: #fff;
    background-color: #4c4c4c;
    opacity: 0.5;
}

.mobile-stepper-container {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;

    .root {
        width: 100%;
        background: transparent;
    }
}

#right-button {
    color: #fff;
    background-color: #4c4c4c;
    margin: auto 0;
    height: 40px;
    min-width: 40px;
    opacity: 0.4;
    position: absolute;
    left: 12px;
}

#left-button {
    color: #fff;
    background-color: #4c4c4c;
    right: 12px;
    position: absolute;
    height: 40px;
    min-width: 40px;
    opacity: 0.5;
}

.grid-tile {
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
}

.MuiMobileStepper-root>.MuiMobileStepper-dots {
    display: none;
}

#service-dialog-content {
    .normal-text {
        @media (max-width: 380px) {
            font-size: 0.8rem;
        }
    }

    .bold-text {
        font-weight: bold;

        @media (max-width: 380px) {
            font-size: 0.8rem;
        }
    }

    .underline-text {
        text-decoration: underline;

        @media (max-width: 380px) {
            font-size: 0.8rem;
        }
    }

}

.list {
    margin: 8px 0;
}

.information-list {
    padding: 24px 24px 0 24px;

    li {
        padding: 8px 0;
    }
}