$border-grey: #EFEEEB;

.header {
    border-bottom: 2px solid $border-grey;
    padding: 0 16px 0 8px;

    .logo {
        width: 132px;
        height: 132px;

        @media (max-width: 580px) {
            width: 100px;
            height: 100px;
        }
    }

    @media (max-width: 580px) {
        .header-content {
            display: none;
        }
    }

    .MuiTypography-body1 {
        font-size: 0.9rem;
    }

    .MuiGrid-spacing-xs-2>.MuiGrid-item.separator-line {
        padding: 0;
    }

    .separator-line {
        padding: 0;
        margin-left: 16px;
        margin-right: 16px;
        height: 60px;
        border-left: 2px solid $border-grey;
    }

    .icon {
        margin: 0 4px 0 0;
    }

    .login-button {
        color: #555;
        border-color: #555;

        &:hover {
            color: #555;
            background-color: #f5f5f5;
            border-color: #555;
        }
    }
}

.link {
    color: inherit;

    &:hover {
        cursor: pointer;
    }
}

.link.MuiTypography-colorPrimary {
    color: inherit;
}